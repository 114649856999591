<section [class.open]="display" class="fixed inset-0 overflow-y-auto z-40" (click)="close()">

  <div class="flex items-center justify-center min-h-screen md:pt-4 md:px-4 md:pb-20 text-center sm:p-0 bg-gray-900 bg-opacity-40">
    <div
      (click)="$event.stopPropagation()"
      class="inline-block align-bottom bg-white md:rounded-lg text-left overflow-hidden shadow-xl transform transition-all md:my-8 md:h-full h-screen md:align-middle md:max-w-xl w-screen"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</section>
